import React from "react";
import { Route, Routes } from "react-router-dom";
import Authenticate from "./components/Authenticate/authenticate";
import Login from "./components/Login/login";
import Dashboard from "./components/Dashboard/dashboard";
import Watches from "./components/Watches/watches";
import Clients from "./components/Clients/clients";
import Settings from "./components/Settings/settings";
import Details from "./components/Watch-Details/details";
import PrivateRoute from "./PrivateRoute";
import Forget from "./components/Forget-Password/forget";
import ClientDetail from "./components/ClientDetail/clientDetail";
import "./App.css";
import Nfts from "./components/NftsPage/nfts";
import NftDetail from "./components/NftsPage/NftDetail";
import UserWatches from "./components/Mantainance/userWatchesTable"

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/forget-password/:token" element={<Forget />} />
      <Route exact path="/authenticate" element={<Authenticate />} />
      <Route
        exact
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/watch"
        element={
          <PrivateRoute>
            <Watches />
          
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/clients"
        element={
          <PrivateRoute>
            <Clients />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/settings"
        element={
          <PrivateRoute>
            <Settings />
          </PrivateRoute>
        }
      />

<Route
        exact
        path="/certifiedwatches"
        element={
          <PrivateRoute>
             <UserWatches/>
          </PrivateRoute>
        }
      />



      <Route
        exact
        path="/watch-detail/:id"
        element={
          <PrivateRoute>
            <Details />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/client-detail/:id"
        element={
          <PrivateRoute>
            <ClientDetail />
          </PrivateRoute>
        }
      />
      <Route exact path="/Nfts" element={<Nfts />} />
      <Route exact path="/Nft-Detail/:id" element={<NftDetail />} />
      {/* <Route path="*" element={<Navigate to="/" />}></Route> */}
    </Routes>
  );
}

export default App;
