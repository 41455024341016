import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import UpdateAttributeForm from "../../Mantainance/updateAttributeForm";
import "./watchDetails.css";
import { DetailsTable } from "../../Mantainance/shared/tables";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { _fetchWatchDetails } from "../../Mantainance/maintainanceAPIs";

import { TransactionTable } from "../../Mantainance/transactionTable";

export default function WatchDetail(props) {
  const [errors, setErrors] = React.useState({});
  const [watch, setWatch] = useState(null);
  const { theme } = useSelector((store) => store.theme);
  const [watchAttributes, setWatchAttributes] = useState(null);
  const [otpCode, setOtpCode] = useState(null);
  const [openOtpConfig, setOpenOtpConfig] = useState(false);
  const [attributeList, setAttributeList] = useState([]);
  const [key, setKey] = useState("");
  // status for geting the watch metadata
  const [status, setStatus] = useState({
    loading: true,
    success: false,
    error: null,
  });
  // status used while updating process
  const [updateStatus, setUpdateStatus] = useState({
    loading: false,
    success: false,
    error: null,
  });

  const [watchTransaction, setWatchTransaction] = useState({
    timestamp: null,
    maintenance: [],
    transfer: [],
    warranty: [],
    certificate: null,
  });

  const [otpExpirationTime, setOtpExpirationTime] = useState(
    Date.now() + 120000
  ); // 2 minutes in milliseconds

  // generic setter function to update the attributes of the watch
  const handleAttributeChange = (attribute, value) => {
    setWatch((prevState) => ({
      ...prevState,
      attributes: {
        ...prevState.attributes,
        [attribute]: value,
      },
    }));
  };

  console.log("watch", watch, "props.watch", props.watch);

  useEffect(() => {
    if (props.watch) {
      //   fetchData();
      _fetchWatchDetails(
        props,
        setWatch,
        setWatchAttributes,
        setStatus,
        setUpdateStatus,
        setWatchTransaction
      );
    }
  }, [props]);

  useEffect(() => {
    if (watch && watch.attributes) {
      setAttributeList(Object.keys(watch.attributes));
    }
    if (
      watchTransaction.transfer != null &&
      props.watch.isCertified &&
      watchTransaction.transfer.length != 0
    ) {
      setKey("Transfer History");
    } else if (
      props.watch.isCertified &&
      watchTransaction.warranty.length != 0
    ) {
      setKey("Warranty History");
    } else if (
      props.watch.isCertified &&
      watchTransaction.maintenance.length != 0
    ) {
      setKey("Maintenance History");
    }
  }, [watch]);

  return (
    <Modal
      show={true}
      onHide={() => {
        props.setMetaModalClose();
      }}
      centered
      style={{
        width: "100%",
        height: "95%",
        margin: "auto",
      }}
    >
      <Modal.Body
        className="collapse-animation"
        style={{
          // maxWidth: "none",
          width: "100%",
          // minWidth:"100%",
          height: "95%",
          margin: "auto",
        }}
      >
        <form>
          <div>
            <div className="d-flex justify-content-between pb-2">
              <div className="update-watch-form-heading">
                {props.readOnly ? "Watch" : "Update Watch"}
              </div>
              <button
                type="button"
                className="btn-close p-1"
                onClick={() => {
                  props.setMetaModalClose();
                }}
                style={{
                  backgroundColor: "#E2E2E2",
                  borderRadius: "200px",
                }}
              ></button>
            </div>

            {!openOtpConfig && (
              <>
                <div className="d-flex justify-content-center align-items-center fileDrag mb-1">
                  <div className="d-flex flex-column align-items-center pt-2 pb-2">
                    <img
                      className="w-90 "
                      src={props.watch.imageURI}
                      alt="img"
                      style={{ maxHeight: "100px" }}
                    />
                  </div>
                </div>

                {status.loading ? (
                  <div
                    style={{ padding: "15px" }}
                    className="d-flex justify-content-center "
                  >
                    <div className="spinner-border text-dark" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : status.error != null && status.error !== "" ? (
                  <div
                    className="main-heading pt-3 pb-3"
                    style={{ width: "max-content", padding: "10px" }}
                  >
                    {status.error}
                  </div>
                ) : null}
              </>
            )}

            {openOtpConfig == false && watch != null && (
              <>
                {watchTransaction.certificate != null && (
                  <div
                    style={{ paddingBottom: "10px" }}
                    className="d-flex justify-content-center"
                  >
                    <a
                      href={watchTransaction.certificate}
                      target="_blank"
                      download
                      className="text-decoration-none transfer-btn p-1"
                    >
                      Download Pdf
                    </a>
                  </div>
                )}
                <div className="d-sm-flex flex-row">
                  <div className="d-flex flex-column flex-grow-1 me-sm-4">
                    <span className="update-watch-form-text">Watch Name</span>
                    <input
                      type="text"
                      name="watch_name"
                      value={watch.watchName}
                      style={{
                        backgroundColor: "#bfcfe8",
                        pointerEvents: "none",
                      }}
                      readOnly
                      // onChange={handleInputChange}
                      className="input-box mb-2 p-1"
                    />
                    {errors.watch_name && (
                      <span className="error-field">{errors.watch_name}</span>
                    )}
                  </div>

                  <div className="d-flex flex-column flex-grow-1">
                    <span className="update-watch-form-text">Brand Name</span>
                    <input
                      type="text"
                      readOnly
                      name="brand_name"
                      value={watch.brandName}
                      style={{
                        backgroundColor: "#bfcfe8",
                        pointerEvents: "none",
                      }}
                      className="input-box mb-2 p-1"
                    />
                    {errors.brand_name && (
                      <span className="error-field">{errors.brand_name}</span>
                    )}
                  </div>
                </div>

                {!props.watch.isCertified && (
                  <div className="d-flex flex-column flex-grow-1 ">
                    <span className="update-watch-form-text">Price</span>
                    <input
                      type="text"
                      readOnly
                      name="watch_name"
                      style={{
                        backgroundColor: "#bfcfe8",
                        pointerEvents: "none",
                      }}
                      value={watch.price}
                      // onChange={handleInputChange}
                      className="input-box mb-2 p-1"
                    />
                    {errors.watch_name && (
                      <span className="error-field pt-1">
                        {errors.watch_name}
                      </span>
                    )}
                  </div>
                )}

                {props.watch.isCertified && (
                  <>
                    <div className="d-sm-flex flex-row">
                      <div className="d-flex flex-column flex-grow-1 me-sm-4">
                        <span className="update-watch-form-text">Price</span>
                        <input
                          type="text"
                          readOnly
                          name="watch_name"
                          style={{
                            backgroundColor: "#bfcfe8",
                            pointerEvents: "none",
                          }}
                          value={`${watch.price} USD`}
                          // onChange={handleInputChange}
                          className="input-box mb-2 p-1"
                        />
                        {errors.watch_name && (
                          <span className="error-field pt-1">
                            {errors.watch_name}
                          </span>
                        )}
                      </div>

                      <div className="d-flex flex-column flex-grow-1">
                        <span className="update-watch-form-text">Token ID</span>
                        <input
                          type="text"
                          readOnly
                          name="brand_name"
                          style={{
                            backgroundColor: "#bfcfe8",
                            pointerEvents: "none",
                          }}
                          value={watch.tokenId}
                          // onChange={handleInputChange}
                          className="input-box mb-2 p-1"
                        />
                        {errors.brand_name && (
                          <span className="error-field">
                            {errors.brand_name}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="d-sm-flex flex-row">
                      <div className="d-flex flex-column flex-grow-1 me-sm-4">
                        <span className="update-watch-form-text">
                          Warranty Start Date
                        </span>
                        <input
                          type="text"
                          readOnly
                          name="watch_name"
                          style={{
                            backgroundColor: "#bfcfe8",
                            pointerEvents: "none",
                          }}
                          value={watch.warrantyStartDate.split("T")[0]}
                          // onChange={handleInputChange}
                          className="input-box mb-2 p-1"
                        />
                        {errors.watch_name && (
                          <span className="error-field pt-1">
                            {errors.watch_name}
                          </span>
                        )}
                      </div>

                      <div className="d-flex flex-column flex-grow-1">
                        <span className="update-watch-form-text">
                          Warranty End Date
                        </span>
                        <input
                          type="text"
                          readOnly
                          name="brand_name"
                          style={{
                            backgroundColor: "#bfcfe8",
                            pointerEvents: "none",
                          }}
                          value={watch.warrantyEndDate.split("T")[0]}
                          // onChange={handleInputChange}
                          className="input-box mb-2 p-1"
                        />
                        {errors.brand_name && (
                          <span className="error-field">
                            {errors.brand_name}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="d-flex align-items-center mb-2">
                      <div className="add-watch-form-heading2">Attributes</div>
                      <div
                        className="w-100 ms-3"
                        style={{
                          border: "1px solid #E2E2E2",
                          borderRadius: "120px",
                        }}
                      ></div>
                    </div>

                    {/* Attributes */}

                    <div className="d-sm-flex flex-row">
                      {/* First Column */}
                      <div className="d-flex flex-column flex-grow-1 me-sm-4 mb-1">
                        {attributeList
                          .slice(0, Math.ceil(attributeList.length / 2))
                          .map((attribute, index) => (
                            <div key={index}>
                              <UpdateAttributeForm
                                watch={watch}
                                attribute={attribute}
                                onChange={handleAttributeChange}
                                errors={errors}
                                readOnly={props.readOnly}
                              />
                            </div>
                          ))}
                      </div>

                      {/* Second Column */}
                      <div className="d-flex flex-column flex-grow-1 mb-1">
                        {attributeList
                          .slice(Math.ceil(attributeList.length / 2))
                          .map((attribute, index) => (
                            <div key={index}>
                              <UpdateAttributeForm
                                watch={watch}
                                attribute={attribute}
                                onChange={handleAttributeChange}
                                errors={errors}
                                readOnly={props.readOnly}
                              />
                            </div>
                          ))}
                      </div>
                    </div>

                    <div
                      className="pb-4 pt-1 "
                      style={{ background: "#FFFFFF", borderRadius: "2px" }}
                    >
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="m-1 p-1 d-flex justify-content-between"
                        style={{
                          background: "#FFFFFF",

                          borderRadius: "2px",
                          width: "100%",
                        }}
                      >
                        <Tab
                          eventKey="Transfer History"
                          title="Transfer History"
                          tabClassName="table-tab"
                        >
                          {watchTransaction.transfer != null &&
                          watchTransaction.transfer.length != 0 ? (
                            <>
                              <div style={{ margin: 0, padding: 0 }}>
                                <DetailsTable
                                  transactions={watchTransaction.transfer}
                                />
                              </div>
                            </>
                          ) : (
                            <div
                              style={{
                                margin: "10px 0",
                                padding: "10px",
                                borderRadius: "4px",
                              }}
                            >
                              No Data Available
                            </div>
                          )}
                        </Tab>

                        <Tab
                          eventKey="Warranty History"
                          title="Warranty History"
                        >
                          {watchTransaction.warranty != null &&
                          watchTransaction.warranty.length != 0 ? (
                            <>
                              <div style={{ margin: 0, padding: 0 }}>
                                <DetailsTable
                                  transactions={watchTransaction.warranty}
                                />
                              </div>
                            </>
                          ) : (
                            <div
                              style={{
                                margin: "10px 0",
                                padding: "10px",
                                borderRadius: "4px",
                              }}
                            >
                              No Data Available
                            </div>
                          )}
                        </Tab>
                        <Tab
                          eventKey="Maintenance History"
                          title="Maintenance History"
                        >
                          {watchTransaction.maintenance != null &&
                          watchTransaction.maintenance.length != 0 ? (
                            <>
                              <div style={{ margin: 0, padding: 0 }}>
                                <DetailsTable
                                  transactions={watchTransaction.maintenance}
                                />
                              </div>
                            </>
                          ) : (
                            <div
                              style={{
                                margin: "10px 0",
                                padding: "10px",
                                borderRadius: "4px",
                              }}
                            >
                              No Data Available
                            </div>
                          )}
                        </Tab>
                      </Tabs>
                    </div>
                  </>
                )}
              </>
            )}
            {watchTransaction.timestamp != null && (
              <>
                <div className="d-flex align-items-center">
                  <div className="add-watch-form-heading2">
                    Transaction History
                  </div>
                  <div
                    className="w-100 ms-1"
                    style={{
                      border: "1px solid #E2E2E2",
                      borderRadius: "120px",
                    }}
                  ></div>
                </div>

                <div style={{ margin: 0, padding: 0 }}>
                  <TransactionTable transactions={watchTransaction.timestamp} />
                </div>
              </>
            )}
            {updateStatus.error != null && updateStatus.error !== "" && (
              <div
                className="error-message"
                style={{
                  fontSize: "14px",
                  backgroundColor: "#f8d7da",
                  color: "#721c24",
                  padding: "3px",
                  borderRadius: "5px",
                }}
              >
                {updateStatus.error}
              </div>
            )}
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
