import React from "react";
import { useLocation, NavLink, useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { themeToggler } from "../../store/themeSlice";
import { menuBar } from "../../store/menuBarSlice";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";

// import logoIcon from "../../assets/images/logo-icon.svg";
// import logoIcon from "../../assets/images/dwiss-icon.svg";

// dwiss Icon
// import logoIcon from "../../assets/images/dwiss new logo.png";
// Timless icon
// import logoIcon from "../../assets/logos/timeless-logo.png";

import { logo } from "../../constant";

import watchIcon from "../../assets/images/watch-icon.svg";
import dashboardIcon from "../../assets/images/dashboard-icon.svg";
import settingIcon from "../../assets/images/setting-icon.svg";
import clientIcon from "../../assets/images/client-icon.svg";
import watchIcon2 from "../../assets/images/watch-icon3.svg";
import dashboardIcon2 from "../../assets/images/dashboard-icon2.svg";
import settingIcon2 from "../../assets/images/setting-icon2.svg";
import MaintainanceIconSelected from "../../assets/images/maintainanceBlue.svg";
import MaintainanceIcon from "../../assets/images/maintainanceblack.svg";
import clientIcon2 from "../../assets/images/client-icon2.svg";
import contactIcon from "../../assets/images/contact-us-logo-icon.svg";
import "./tabs.css";

function Tabs() {
  const { menu } = useSelector((store) => store.menu);

  const handleClose = () => {
    dispatch(menuBar());
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const { theme } = useSelector((store) => store.theme);
  const dispatch = useDispatch();

  const handleContactUsClick = (e) => {
    const email = "twiny.mobiledev@digital-owner.com";
    const subject = "Contact Us";
    const body = "Hello, I would like to inquire about...";

    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.open(gmailUrl, "_blank");

    e.preventDefault();
  };

  return (
    <>
      <Navbar sticky="top" className={`d-md-block d-none navbar-${theme} p-1`}>
        <Nav
          className="d-flex justify-content-between flex-column p-2"
          style={{ width: "100%", height: "100vh", marginRight: "0px" }}
          activeKey={location.pathname}
        >
          <div className="d-flex flex-column">
            <Navbar.Brand
            // style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "30vh",
                  // height:"15vh",
                }}
                className="p-3"
                onClick={() => {
                  navigate("/dashboard");
                }}
              />
            </Navbar.Brand>
            <NavLink
              to="/dashboard"
              className="text-decoration-none navbar-text-style d-flex justify-content-start p-2"
            >
              <div>
                {location.pathname === "/dashboard" ? (
                  <img
                    src={dashboardIcon2}
                    alt="icon"
                    className="pe-3"
                    style={{
                      width: "2.5rem",
                    }}
                  />
                ) : (
                  <img
                    src={dashboardIcon}
                    alt="icon"
                    className="pe-3"
                    style={{
                      width: "2.5rem",
                    }}
                  />
                )}
                Dashboard
              </div>
            </NavLink>
            {location.pathname === `/watch-detail/${id}` ? (
              <NavLink
                to={`/watch-detail/${id}`}
                className="text-decoration-none navbar-text-style d-flex justify-content-start p-2"
              >
                <div>
                  {location.pathname === `/watch-detail/${id}` ? (
                    <img
                      src={watchIcon2}
                      alt="watch-icon"
                      className="pe-3"
                      style={{
                        width: "2.5rem",
                      }}
                    />
                  ) : (
                    <img
                      src={watchIcon}
                      alt="watch-icon"
                      className="pe-3"
                      style={{
                        width: "2.5rem",
                      }}
                    />
                  )}
                  Watches
                </div>
              </NavLink>
            ) : (
              <NavLink
                to="/watch"
                className="text-decoration-none navbar-text-style d-flex justify-content-start p-2"
              >
                <div>
                  {location.pathname === "/watch" ? (
                    <img
                      src={watchIcon2}
                      alt="watch-icon"
                      className="pe-3"
                      style={{
                        width: "2.5rem",
                      }}
                    />
                  ) : (
                    <img
                      src={watchIcon}
                      alt="watch-icon"
                      className="pe-3"
                      style={{
                        width: "2.5rem",
                      }}
                    />
                  )}
                  Watches
                </div>
              </NavLink>
            )}

            {location.pathname === `/client-detail/${id}` ? (
              <NavLink
                to={`/client-detail/${id}`}
                className="text-decoration-none navbar-text-style d-flex justify-content-start p-2"
              >
                <div>
                  {location.pathname === `/client-detail/${id}` ? (
                    <img
                      src={clientIcon2}
                      alt="client-icon"
                      className="pe-3"
                      style={{
                        width: "2.5rem",
                      }}
                    />
                  ) : (
                    <img
                      src={clientIcon}
                      alt="client-icon"
                      className="pe-3"
                      style={{
                        width: "2.5rem",
                      }}
                    />
                  )}
                  Clients
                </div>
              </NavLink>
            ) : (
              <NavLink
                to="/clients"
                className="text-decoration-none navbar-text-style d-flex justify-content-start p-2"
              >
                <div>
                  {location.pathname === "/clients" ? (
                    <img
                      src={clientIcon2}
                      alt="client-icon"
                      className="pe-3"
                      style={{
                        width: "2.5rem",
                      }}
                    />
                  ) : (
                    <img
                      src={clientIcon}
                      alt="client-icon"
                      className="pe-3"
                      style={{
                        width: "2.5rem",
                      }}
                    />
                  )}
                  Clients
                </div>
              </NavLink>
            )}

            <NavLink
              to="/settings"
              className="text-decoration-none navbar-text-style d-flex justify-content-start p-2"
            >
              <div>
                {location.pathname === "/settings" ? (
                  <img
                    src={settingIcon2}
                    alt="setting-icon"
                    className="pe-3"
                    style={{
                      width: "2.5rem",
                    }}
                  />
                ) : (
                  <img
                    src={settingIcon}
                    alt="setting-icon"
                    className="pe-3"
                    style={{
                      width: "2.5rem",
                    }}
                  />
                )}
                Settings
              </div>
            </NavLink>

            <NavLink
              to="/certifiedwatches"
              className="text-decoration-none navbar-text-style d-flex justify-content-start p-2"
            >
              <div
                style={{
                  marginLeft: "5px",
                }}
              >
                {location.pathname === "/certifiedwatches" ? (
                  <img
                    src={MaintainanceIconSelected}
                    alt="maintainance-icon"
                    className="pe-3"
                    style={{
                      width: "2.0rem",
                    }}
                  />
                ) : (
                  <img
                    src={MaintainanceIcon}
                    alt="maintainance-icon"
                    className="pe-3"
                    style={{
                      width: "2.0rem",
                    }}
                  />
                )}
                Certified Watches
              </div>
            </NavLink>
          </div>
          <div>
            <div className="contact-us p-4 mt-5">
              <p className="contact-us-heading pb-2"> Do you need our help?</p>
              <p className="contact-us-text pb-3">
                Send your request via email.
              </p>
              {/* <button className="contact-us-btn "> */}
              <button
                className="contact-us-btn ps-3 pe-3"
                onClick={handleContactUsClick}
              >
                Contact Us
                <img src={contactIcon} alt="contact-icon" className="ps-3" />
              </button>
            </div>
          </div>
        </Nav>
      </Navbar>
      <Offcanvas
        className={`d-md-none navbar-${theme} p-1`}
        show={menu}
        onHide={handleClose}
        placement={"start"}
        style={{ width: "fit-content" }}
      >
        <div
          className="d-flex justify-content-between flex-column"
          style={{ height: "98vh" }}
        >
          <div className="d-flex flex-column ">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <img
                  src={logo}
                  alt="logo"
                  style={{ height: "8vh" }}
                  className="p-2"
                  onClick={() => {
                    navigate("/dashboard");
                  }}
                />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div>
                <NavLink
                  to="/dashboard"
                  onClick={handleClose}
                  className="text-decoration-none navbar-text-style d-flex justify-content-start p-2"
                >
                  <div>
                    {location.pathname === "/dashboard" ? (
                      <img
                        src={dashboardIcon2}
                        alt="icon"
                        className="pe-3"
                        style={{
                          width: "2.5rem",
                        }}
                      />
                    ) : (
                      <img
                        src={dashboardIcon}
                        alt="icon"
                        className="pe-3"
                        style={{
                          width: "2.5rem",
                        }}
                      />
                    )}
                    Dashboard
                  </div>
                </NavLink>
                {location.pathname === `/watch-detail/${id}` ? (
                  <NavLink
                    to={`/watch-detail/${id}`}
                    onClick={handleClose}
                    className="text-decoration-none navbar-text-style d-flex justify-content-start p-2"
                  >
                    <div>
                      {location.pathname === `/watch-detail/${id}` ? (
                        <img
                          src={watchIcon2}
                          alt="watch-icon"
                          className="pe-3"
                          style={{
                            width: "2.5rem",
                          }}
                        />
                      ) : (
                        <img
                          src={watchIcon}
                          alt="watch-icon"
                          className="pe-3"
                          style={{
                            width: "2.5rem",
                          }}
                        />
                      )}
                      Watches
                    </div>
                  </NavLink>
                ) : (
                  <NavLink
                    to="/watch"
                    onClick={handleClose}
                    className="text-decoration-none navbar-text-style d-flex justify-content-start p-2"
                  >
                    <div>
                      {location.pathname === "/watch" ? (
                        <img
                          src={watchIcon2}
                          alt="watch-icon"
                          className="pe-3"
                          style={{
                            width: "2.5rem",
                          }}
                        />
                      ) : (
                        <img
                          src={watchIcon}
                          alt="watch-icon"
                          className="pe-3"
                          style={{
                            width: "2.5rem",
                          }}
                        />
                      )}
                      Watches
                    </div>
                  </NavLink>
                )}
                {location.pathname === `/client-detail/${id}` ? (
                  <NavLink
                    to={`/client-detail/${id}`}
                    onClick={handleClose}
                    className="text-decoration-none navbar-text-style d-flex justify-content-start p-2"
                  >
                    <div>
                      {location.pathname === `/client-detail/${id}` ? (
                        <img
                          src={clientIcon2}
                          alt="client-icon"
                          className="pe-3"
                          style={{
                            width: "2.5rem",
                          }}
                        />
                      ) : (
                        <img
                          src={clientIcon}
                          alt="client-icon"
                          className="pe-3"
                          style={{
                            width: "2.5rem",
                          }}
                        />
                      )}
                      Clients
                    </div>
                  </NavLink>
                ) : (
                  <NavLink
                    to="/clients"
                    onClick={handleClose}
                    className="text-decoration-none navbar-text-style d-flex justify-content-start p-2"
                  >
                    <div>
                      {location.pathname === "/clients" ? (
                        <img
                          src={clientIcon2}
                          alt="client-icon"
                          className="pe-3"
                          style={{
                            width: "2.5rem",
                          }}
                        />
                      ) : (
                        <img
                          src={clientIcon}
                          alt="client-icon"
                          className="pe-3"
                          style={{
                            width: "2.5rem",
                          }}
                        />
                      )}
                      Clients
                    </div>
                  </NavLink>
                )}

                <NavLink
                  to="/settings"
                  onClick={handleClose}
                  className="text-decoration-none navbar-text-style d-flex justify-content-start p-2"
                >
                  <div>
                    {location.pathname === "/settings" ? (
                      <img
                        src={settingIcon2}
                        alt="setting-icon"
                        className="pe-3"
                        style={{
                          width: "2.5rem",
                        }}
                      />
                    ) : (
                      <img
                        src={settingIcon}
                        alt="setting-icon"
                        className="pe-3"
                        style={{
                          width: "2.5rem",
                        }}
                      />
                    )}
                    Settings
                  </div>
                </NavLink>

                <NavLink
                  to="/certifiedwatches"
                  onClick={handleClose}
                  className="text-decoration-none navbar-text-style d-flex justify-content-start p-2"
                >
                  <div
                    style={{
                      marginLeft: "5px",
                    }}
                  >
                    {location.pathname === "/certifiedwatches" ? (
                      <img
                        src={MaintainanceIconSelected}
                        alt="setting-icon"
                        className="pe-3"
                        style={{
                          width: "2rem",
                        }}
                      />
                    ) : (
                      <img
                        src={MaintainanceIcon}
                        alt="setting-icon"
                        className="pe-3"
                        style={{
                          width: "2rem",
                        }}
                      />
                    )}
                    Maintainance
                  </div>
                </NavLink>
              </div>
            </Offcanvas.Body>
          </div>
          <div>
            <div className="contact-us p-4 mt-5">
              <p className="contact-us-heading pb-2">Do you need our help?</p>
              <p className="contact-us-text pb-3">
                Send your request via email.
              </p>
              <button
                className="contact-us-btn ps-3 pe-3"
                onClick={handleContactUsClick}
              >
                Contact Us
                <img
                  src={contactIcon}
                  alt="contact-icon"
                  className="ps-3 contact-us-btn"
                />
              </button>
            </div>
          </div>
        </div>
      </Offcanvas>
    </>
  );
}

export default Tabs;
